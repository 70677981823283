import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Seo from "../components/seo"
import LayoutTwo from "../components/layoutTwo";

import CareerDetailImg from "../images/career_detail_img.png";
import { PageLinks } from "../common/site/page-static-links";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const CareerDetailDesc = loadable(() => import("../components/CareerDetailDesc/CareerDetailDesc"));

const CareerDetail = ({data}, props) => {
    const PageData = data?.strapiCareer

    let breadcrumData;

    if (PageLinks?.career_parent_label) {
        breadcrumData = { parentlabel: PageLinks.career_parent_label, parentlink: PageLinks.career_parent, subparentlabel: PageLinks.career_label, subparentlink: PageLinks.career, title: PageData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.career_label, parentlink: PageLinks.career, title: PageData.title }
    }

    return (
        <LayoutTwo>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule {...breadcrumData} />

            <StaticIntro {...PageData} tag="career-detail" />

            <CareerDetailDesc data={PageData} />
        </LayoutTwo>
    )
}


export const Head = ({ data }) => {
    const PageData = data?.strapiCareer
    const metaDescription = `Join our dynamic team as a ${PageData.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`
  
    return (
      <Seo title={PageData.title} description={metaDescription}/>
    )
  }
  
  export default CareerDetail
  
  export const query = graphql`
  query ($page_id: Int) {
      strapiCareer(strapi_id: {eq: $page_id}) {
        ...CareerFragment
          job_details {
            data {
              job_details
            }
          }
          image {
            alternativeText
            url
          }
          imagetransforms {
            image_Transforms
          }
      }
    }
  `